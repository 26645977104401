import React, { Component } from 'react';
import { Row, Col, Image, Form, Media, Accordion, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactJWPlayer from 'react-jw-player';
import ContentLoader from "../../components/Static/contentLoader";
import Helper from "../../components/Helper/helper";
import api from "../../Environment";
import io from "socket.io-client";
import { apiConstants } from "../../components/Constant/constants";
import LinearProgress from "@material-ui/core/LinearProgress";
import ToastDemo from "../Helper/toaster";
import configuration from "react-global-configuration";

const socket = apiConstants.socketUrl ? io(apiConstants.socketUrl) : "";
let userId = localStorage.getItem("userId");
let accessToken = localStorage.getItem("accessToken");
const $ = window.$;

class VideoPLayerIndex extends Helper {

    state = {
        loadingFirst: true,
        videoDetailsFirst: null,
        onPlayStarted: false,
        videoList: {},
        videoData: null,
        videoId: 0,
        socket: false,
        query: "",
        onSeekPlay: true,
        socketConnection: true,
        videoDuration: 0,
        socketConnected: false,
        videoPlayerInfo: null,
        loadingPlayerInfo: true,
        currentDuration: 0,
        playbackSpeed: '1x',
        currentVolume: 50,
        playButtonClicked: false,
        inputData: {},
    };

    componentDidMount() {
        if (this.props.location.state) {

            this.setState({ videoDetailsFirst: this.props.location.state.videoDetailsFirst });
            let inputData = {
                admin_video_id: this.props.location.state.videoDetailsFirst
                    .admin_video_id,
            };

            api
                .postMethod("videos_ads", inputData)
                .then((response) => {
                    if (response.data.success === true) {
                        let videoPlayerInfo = response.data.data;
                        this.setState({
                            loadingPlayerInfo: false,
                            videoPlayerInfo: videoPlayerInfo,
                        });
                    } else {
                        this.setState({ videoPlayerInfo: response.data });
                    }
                })
                .catch(function (error) { });

            this.setState({ loadingFirst: false });
        } else {
            window.location = "/home";
        }
    }

    timer = async () => {
        if (this.state.onPlayStarted) {
            await this.socketConnectionfun(userId, accessToken);
        }
    };

    componentWillUnmount() {
        // use intervalId from the state to clear the interval
        clearInterval(this.state.intervalId);
    }

    onCompleteVideo = () => {
        this.addHistory(this.state.videoDetailsFirst.admin_video_id);
        this.setState({ onPlayStarted: false, socketConnection: false });
        if (this.state.socketConnected) {
            socket.emit("disconnect");
        }
    };

    onVideoPlay = async () => {

        let intervalId = setInterval(this.timer, 3000);

        this.setState({ intervalId: intervalId });

        this.setState({ onPlayStarted: true, socketConnection: true });

        let inputData = {
            admin_video_id: this.state.videoDetailsFirst
                .admin_video_id,
        };
        await this.onlySingleVideoFirst(inputData);
        this.redirectStatus(this.state.videoDetailsFirst);

        const seekTime = this.state.videoDetailsFirst.seek_time_in_seconds
            ? this.state.videoDetailsFirst.seek_time_in_seconds
            : 0;

        if (this.state.onSeekPlay) {
            const player = window.jwplayer('my-unique-id');
            player.seek(parseFloat(seekTime));
        }

        this.setState({ onSeekPlay: false });

        if (this.state.videoDetailsFirst.skip_intro_seconds > this.state.currentDuration) {
            $('#skip-intro-sec').hide();
        }

    };

    addHistory = (admin_video_id) => {
        api
            .postMethod("addHistory", { admin_video_id: admin_video_id })
            .then((response) => {
                if (response.data.success === true) {
                } else {
                }
            })
            .catch(function (error) { });
    };


    ref = (player) => {
        this.player = player;
    };

    socketConnectionfun = (userId, accessToken) => {
        if (apiConstants.socketUrl) {
            let videoId = this.state.videoDetailsFirst.admin_video_id;

            socket.on("connect", function () {
                let query = `user_id=` + userId + `&video_id=` + videoId;
            });

            socket.on("connected", function () {
                console.log("Connected");
                this.setState({ socketConnected: true });
            });

            socket.on("disconnect", function () {
                console.log("disconnect");
                this.setState({ socketConnected: false });
            });

            let videoData = [
                {
                    sub_profile_id: localStorage.getItem("active_profile_id"),
                    admin_video_id: videoId,
                    id: userId,
                    token: accessToken,
                    duration: this.state.currentDuration,
                },
            ];

            console.log("currentDuration" + this.state.currentDuration);

            socket.emit("save_continue_watching_video", videoData[0]);
        }
    };

    onPauseVideo = async () => {
        console.log("onPause");
        if (this.state.socketConnected) {
            socket.emit("disconnect");
        }
        clearInterval(this.state.intervalId);
    };

    onVideoTimeUpdate = (duration) => {
        let video_duration = duration.target.currentTime;

        let sec = parseInt(video_duration % 60);
        let min = parseInt((video_duration / 60) % 60);
        let hours = parseInt(video_duration / 3600);

        if (hours > 1) {
            this.setState({ videoDuration: hours ? hours : "00" + ":" + min ? min : "00" + ":" + sec ? sec : "00" });
        } else {
            this.setState({ videoDuration: "00:" + min ? min : "00" + ":" + sec ? sec : "00" });
        }
    };

    playerBackward = event => {
        event.preventDefault();
        const player = window.jwplayer('my-unique-id');

        if (player.getPosition() > 10) {

            var newPosition = player.getPosition() - 10;

            player.seek(newPosition);

        }
    };

    playerForward = event => {
        event.preventDefault();
        const player = window.jwplayer('my-unique-id');

        var newPosition = player.getPosition() + 10;

        player.seek(newPosition);
    };

    playerVolume = (event, value) => {
        event.preventDefault();
        const player = window.jwplayer('my-unique-id');

        const setVolume = parseInt(value);
        if (setVolume <= 0) {

            player.setMute(true);

            if (player.getMute() == true) {

                $('#player-unmute-button-item').hide();
                $('#player-mute-button-item').show();

            }
        } else {
            player.setMute(false);
        }

        player.setVolume(setVolume);

        this.setState({
            currentVolume: value,
        });
    };

    playerPlay = (event) => {
        event.preventDefault();
        const player = window.jwplayer('my-unique-id');
        player.play();
        $('#player-play-item').hide();
        $('#player-pause-item').show();
    };

    playerPause = (event) => {
        event.preventDefault();
        const player = window.jwplayer('my-unique-id');
        player.pause();
        $('#player-pause-item').hide();
        $('#player-play-item').show();
    };

    playerMute = (event) => {

        event.preventDefault();
        const player = window.jwplayer('my-unique-id');
        player.setMute(true);
        $('#player-unmute-button-item').hide();
        $('#player-mute-button-item').show();
        this.setState({
            currentVolume: 0,
        });
    };

    playerUnmute = (event) => {
        event.preventDefault();
        const player = window.jwplayer('my-unique-id');
        player.setMute(false);
        this.setState({
            currentVolume: player.getVolume(),
        });
        $('#player-unmute-button-item').show();
        $('#player-mute-button-item').hide();
    };

    playerDuration = (e, value) => {
        let player = window.jwplayer('my-unique-id');
        let total_duration = player.getDuration();
        let current_position = e.position;
        console.log("current_position" + current_position);
        // let sec = parseInt(current_position % 60);
        // sec = sec < 10 ? "0"+sec  : sec;

        // let min = parseInt((current_position / 60) % 60);
        // min = min < 10 ? "0"+min  : min;

        // let hours = parseInt(current_position / 3600);
        // hours = hours < 10 ? "0"+hours  : hours;

        // let currentPlay = hours + ":" + min + ":" + sec;
        // this.setState({ currentDuration: currentPlay });

        let percentage = (current_position / total_duration) * 100;

        this.setState({
            currentDuration: percentage
        });
    };

    handlePlayVideo = async (event, admin_video_id) => {
        event.preventDefault();

        let inputData = {
            ...this.state.inputData,
            admin_video_id: admin_video_id,
        };

        await this.onlySingleVideoFirst(inputData);
        console.log(this.state.videoDetailsFirst.success);
        if (this.state.videoDetailsFirst.success === false) {
            ToastDemo(
                this.props.toastManager,
                this.state.videoDetailsFirst.error_messages,
                "error"
            );
        } else {
            this.redirectStatus(this.state.videoDetailsFirst);
            this.setState({ playButtonClicked: true });
        }
    };

    setPlaybackspeed = (event, value) => {
        event.preventDefault();
        let player = window.jwplayer('my-unique-id');
        player.setPlaybackRate(value);
        this.setState({
            playbackSpeed: value + 'x',
        });
    };

    playerFullscreenOn = (event) => {
        event.preventDefault();
        let player = window.jwplayer('my-unique-id');
        player.setFullscreen(true);
        // $('#player-fullscreen-on').hide();
        // $('#player-fullscreen-off').show();
    };

    playerFullscreenOff = (event) => {
        event.preventDefault();
        let player = window.jwplayer('my-unique-id');
        player.setFullscreen(false);
        $('#player-fullscreen-off').hide();
        $('#player-fullscreen-on').show();
    };

    onReady = () => {
        let player = window.jwplayer('my-unique-id');
    };

    playerSetCaption = (event, value) => {
        event.preventDefault();
        let player = window.jwplayer('my-unique-id');
        console.log(player.getCurrentCaptions());
        player.setCurrentCaptions(value + 1);
    };

    playerSetTrack = (event, value) => {
        event.preventDefault();
        let player = window.jwplayer('my-unique-id');
        console.log(player.getAudioTracks());
        console.log(player.getAudioTracks());
        console.log(player.getCurrentAudioTrack());

        player.setCurrentAudioTrack(value + 1);
    };

    skipIntro = (event, time) => {
        event.preventDefault();
        let player = window.jwplayer('my-unique-id');
        player.seek(time);
        $('#skip-intro-sec').hide();
    };

    render() {

        if (this.state.playButtonClicked) {
            const returnToVideo = this.renderRedirectPage(
                this.state.videoDetailsFirst
            );

            if (returnToVideo != null) {
                return returnToVideo;
            }
        }

        // const pageType = "videoPage";

        // if (this.state.onPlayStarted) {

        //     const returnToVideo = this.renderRedirectPage(
        //         this.state.videoDetailsFirst,
        //         pageType
        //     );
        //     console.log(returnToVideo);
        //     if (returnToVideo != null) {
        //         return returnToVideo;
        //     }
        // }
        const { loadingFirst, loadingPlayerInfo, playbackSpeed } = this.state;

        let mainVideo, videoTitle, videoType, subTitle;

        if (loadingFirst && loadingPlayerInfo) {
            return <ContentLoader />;
        } else {
            // Check the whether we need to play the trailer or main video

            if (this.props.location.state && this.props.location.state.videoFrom != undefined) {
                subTitle = this.state.videoDetailsFirst.video_subtitle_vtt;

                if (this.props.location.state.videoFrom == "trailer") {
                    mainVideo = this.state.videoDetailsFirst.resolutions.original;
                    subTitle = this.state.videoDetailsFirst.trailer_subtitle;
                } else {
                    mainVideo = this.state.videoDetailsFirst.resolutions.original;
                }

                videoTitle = this.state.videoDetailsFirst.name;

                videoType = this.state.videoDetailsFirst.video_type;
            } else {
                mainVideo = this.state.videoDetailsFirst.main_video;

                subTitle = this.state.videoDetailsFirst.video_subtitle_vtt;

                videoTitle = this.state.videoDetailsFirst.title;

                videoType = this.state.videoDetailsFirst.video_type;
            }

            var scheduleAds = [];

            console.log(this.state.videoPlayerInfo);
            if (!this.state.loadingPlayerInfo && this.state.videoPlayerInfo.video_ads.length > 0) {

                this.state.videoPlayerInfo.video_ads.forEach((resource, index) => {
                    scheduleAds.push({ 'tag': [resource.ad_url], 'type': "linear", 'offset': resource.ad_type_formatted == 'between' ? resource.video_time : resource.ad_type_formatted});
                });
            }
            console.log(scheduleAds);

            let newScheduleArray = [
                {
                    tag: [
                        "https://playertest.longtailvideo.com/vast-30s-ad.xml"
                    ],
                    type: "linear",
                    offset: 25
                },
                {
                    tag: [
                        "https://playertest.longtailvideo.com/vast-30s-ad.xml"
                    ],
                    type: "linear",
                    offset: 125
                },
            ];

            const ads = {
                admessage: "This video will resume in xx seconds",
                adscheduleid: "2eaH8w56",
                client: "vast",
                cuetext: "Advertisement",
                outstream: false,
                preloadAds: false,
                vpaidcontrols: false,
                rules: {
                    startOnSeek: "pre",
                    timeBetweenAds: 10
                },
                schedule: scheduleAds
            };

            const playlist = [
                {
                    file: mainVideo,
                    image: this.state.videoDetailsFirst.default_image,
                }
            ];

            return (
                <>
                    <div className="player-video-sec">
                        <ReactJWPlayer
                            ref={this.ref}
                            height= "100%"
                            width= "100%"
                            aspectRatio='inherit'
                            licenseKey={configuration.get("configData.jw_player_key_web")}
                            playerId="jphY5ltz"
                            playlist={playlist}
                            playerScript="https://cdn.jwplayer.com/libraries/jphY5ltz.js"
                           // onTime={this.playerDuration}
                            onAutoStart={this.onVideoPlay}
                            onReady={this.onReady}
                            onOneHundredPercent={this.onCompleteVideo}
                            onPause={this.onPauseVideo}
                            customProps={{ advertising: { ...ads } }}
                        />
                    </div>
                </>
            );
        }
    }
};

export default VideoPLayerIndex;